import React from "react";

const JobDescription = () => {
  return (
    <div className="mx-auto p-5 bg-gray-100 text-gray-800 py-20">
      <div className="max-w-3xl mx-auto p-5 bg-white shadow-md">
        <h1 className="text-4xl text-blue-700">Job Description</h1>
        <h2 className="text-2xl text-blue-700 mt-4">
          Position Title: Assistant
        </h2>
        <p>
          <strong>Reports to:</strong> Operations Manager
        </p>

        <h2 className="text-2xl text-blue-700 mt-6">About Us</h2>
        <p>
          American High-Tech Transcription & Reporting, Inc. specializes in
          delivering professional transcription services. We value efficiency,
          adaptability, and professionalism, and we're looking for a motivated
          individual to join our dynamic team.
        </p>

        <h2 className="text-2xl text-blue-700 mt-6">Position Summary</h2>
        <p>
          As an <strong>Assistant</strong>, you'll play a vital role in ensuring
          seamless communication and operational support. Your responsibilities
          will include managing emails, handling phone inquiries, and providing
          office coverage, with flexibility to adapt to varying work schedules.
        </p>

        <h2 className="text-2xl text-blue-700 mt-6">Key Responsibilities</h2>
        <h3 className="text-xl text-blue-700 mt-4">
          Communication & Administrative Support
        </h3>
        <ul className="list-disc pl-5">
          <li>
            Answer incoming phone calls and address inquiries with
            professionalism.
          </li>
          <li>
            Respond promptly to emails and maintain accurate records of
            correspondence.
          </li>
        </ul>

        <h3 className="text-xl text-blue-700 mt-4">Coverage & Flexibility</h3>
        <ul className="list-disc pl-5">
          <li>
            Provide consistent support for 4 hours per day, 5 days a week,
            during business hours in the <strong>U.S. Eastern Time Zone</strong>
            .
          </li>
          <li>
            Offer full-day (8-hour) coverage during periods when colleagues are
            out of the office.
          </li>
        </ul>

        <h3 className="text-xl text-blue-700 mt-4">
          Professionalism & Adaptability
        </h3>
        <ul className="list-disc pl-5">
          <li>
            Maintain professionalism and courtesy in all interactions with
            clients, team members, and contractors.
          </li>
          <li>
            Adapt to evolving priorities and workloads to ensure smooth delivery
            of services.
          </li>
        </ul>

        <h3 className="text-xl text-blue-700 mt-4">General Assistance</h3>
        <ul className="list-disc pl-5">
          <li>
            Assist the Operations Manager with assigned tasks and projects.
          </li>
          <li>
            Support overall office operations with additional responsibilities
            as needed.
          </li>
        </ul>

        <div className="bg-yellow-100 border border-yellow-300 p-4 mt-6">
          <strong>Important Considerations:</strong>
          <p>
            Materials transcribed by American High-Tech Transcription &
            Reporting may include content of a graphic, violent, or sexually
            explicit nature. Applicants should carefully consider their comfort
            level with such material before applying.
          </p>
        </div>

        <h2 className="text-2xl text-blue-700 mt-6">
          Training & Evaluation Period
        </h2>
        <p>
          All new employees will undergo a 60-day training and evaluation
          period. This period allows both the employee and employer to assess
          the fit for the role. A written evaluation will be provided near the
          end of the training period.
        </p>

        <h2 className="text-2xl text-blue-700 mt-6">Acknowledgement</h2>
        <p>
          This job description is intended to outline the responsibilities of
          the role and does not constitute an offer of employment.
        </p>
      </div>

      <footer className="text-center mt-6 text-sm text-gray-600">
        &copy; 2025 American High-Tech Transcription & Reporting, Inc. All
        Rights Reserved.
      </footer>
    </div>
  );
};

export default JobDescription;
